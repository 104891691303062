import React from "react";
import logo from "./assets/logo.png";
import webArt from "./assets/webArt.png";

import "./App.css";
import Link from "./Link";

function App() {
  const socials = [
    {
      id: 1,
      title: "Linkedin",
      link: "https://www.linkedin.com/in/kemesh-maharjan-aa14b6a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      id: 2,
      title: "Instagram",
      link: "https://www.instagram.com/hi.kemesh/profilecard/?igsh=MTQyeDAyNHpzZWxtNw==",
    },
    {
      id: 3,
      title: "Whatsapp",
      link: "https://wa.link/00pry4",
    },
    {
      id: 4,
      title: "Khya.co",
      link: "https://www.instagram.com/khya.co/profilecard/?igsh=ejk0cHZwbTcxdDk2",
    },
    {
      id: 5,
      title: "Khya.game",
      link: "https://www.instagram.com/khya.games/profilecard/?igsh=MW45YWo3M3dlNmZtbQ==",
    },
  ];

  return (
    <div className='main flex  flex-col items-center justify-center min-h-screen w-screen'>
      <div className='max-w-[960px] flex flex-col gap-10 p-10'>
        <div className='flex flex-col  items-center justify-center md:ml-32 md:flex-row  '>
          <img src={logo} alt='logo' className='max-h-[225px]' />
          <img src={webArt} alt='web art' className='max-h-[700px] ' />
        </div>
        <div className='flex gap-4 flex-col'>
          <h3 className='font-pixel text-primary'>About Me</h3>
          <p className='font-inter'>
            Hello, I’m Kemesh Maharjan, a Fine Arts graduate specializing in
            Graphic Communication from Kathmandu University, based in Lalitpur,
            Nepal. I am passionate about gaming and technology, with a strong
            interest in creative roles that span from logo design to game
            design, illustration to motion and animation. I thrive in
            collaborative environments, valuing teamwork and team-building as
            key elements of my work. I am also the founder of Khya.co, a merch
            brand and Khya.games, a game design studio in Nepal.
          </p>
        </div>
        <div className='flex flex-col md:flex-row md:justify-between font-pixel '>
          {socials.map((social) => (
            <Link key={social.id} title={social.title} link={social.link} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
