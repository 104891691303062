import React from "react";

interface LinkProps {
  link: string;
  title: string;
}

const Link = ({ link, title }: LinkProps) => {
  return (
    <a
      href={link}
      target='_blank'
      className='text-primary hover:text-secondary active:text-primary'
      rel='noreferrer'
    >
      {title}
    </a>
  );
};

export default Link;
